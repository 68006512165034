import React, { useState, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import TypeWriterEffect from 'react-typewriter-effect';

import { ThemeProvider } from '@emotion/react';
import { Fab, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { apptheme, ColorChangeLink, useWindowSize } from 'common/utils';
import HomeCardDevelop from 'components/homeCardDevelop';
import HomeCardOrigin from 'components/homeCardOrigin';
import News from 'components/news';
import OneCharacterByDisplay from 'components/oneCharacterByDisplay';
import ScrollReveal from 'components/scrollReveal';
import TrimCircle from 'components/trimCircle';
import { COLORS, DELAYTIME, DURATION, TYPEWRITER } from 'const/homeConst';
import { FaXTwitter } from 'react-icons/fa6';
import { ImFacebook } from 'react-icons/im';

import AchievementDataDevelop from '../data/achievementDataDevelop.json';
import AchievementDataOrigin from '../data/achievementDataOrigin.json';

/**
 * Homeページ
 * @returns
 */
const Home: React.FC = () => {
  const [width] = useWindowSize();
  const [news, setNews] = useState<Array<{ date: string; title: string; text: string }>>();
  const isNewsVisible = useRef(true);
  const { t } = useTranslation();

  /**
   * 説明文の文字スタイル
   * @returns
   */
  const infoTypo = () => {
    return {
      fontSize: `max(${width / 80}px, 8px)`,
      color: COLORS.FONT,
      lineHeight: `${width / 30}px`,
    };
  };

  /**
   * 見出し部分の太字文字スタイル
   * @returns
   */
  const infoBoldTypo = () => {
    return {
      fontSize: `max(${width / 60}px, 20px)`,
      color: COLORS.FONT,
      letterSpacing: '10px',
      marginBottom: `${width / 25}px`,
    };
  };

  /**
   * 1文のうち、<b>～</b>に囲まれた、～の部分の文字を太字にする
   * @param str テキスト
   * @returns
   */
  const onePointBold = (str: string) => {
    if (str.indexOf('<b>') < 0) {
      return <span style={infoTypo()}>{str}</span>;
    }
    const result_one = str.split('<b>'); // <b>までを区切る
    const result_two = result_one[1].split('</b>'); // </b>までを区切る
    // 一部分だけ太字にした状態で返す
    return (
      <>
        <span style={infoTypo()}>{result_one[0]}</span>
        <span style={{ fontWeight: 'bold' }}> {result_two[0]} </span>
        <span style={infoTypo()}>{result_two[1]}</span>
      </>
    );
  };

  /**
   * 表示テキストのfor文処理
   * @param text テキスト
   * @param property スタイル設定
   * @returns
   */
  const messageText = (text: string, property: React.CSSProperties = infoTypo()) => {
    const items = [];
    for (let i = 0; i < t(text).length; i++) {
      items.push(
        <li key={i.toString()}>
          <ScrollReveal
            option={{
              duration: DURATION.PHRASE,
              reset: false,
              opacity: 0,
              delay: i * DELAYTIME.PHRASE,
            }}
          >
            <Typography style={property}>{onePointBold(t(text)[i])}</Typography>
          </ScrollReveal>
        </li>
      );
    }
    return <ul style={{ listStyle: 'none', paddingLeft: `${width / 40}px` }}>{items}</ul>;
  };

  /**
   * ニュースとイベントの表示準備をする
   * @param jsonData jsonデータ
   * @returns
   */
  const prepareNews = (jsonData: {
    count: string;
    visiblity: string;
    article: {
      date: string;
      title: string;
      text: string;
    }[];
  }) => {
    if (jsonData.visiblity === 'true') {
      isNewsVisible.current = true;
    } else {
      isNewsVisible.current = false;
    }
    const tmpArray = new Array<{ date: string; title: string; text: string }>();
    // dateを使って並び替える
    const sortData = jsonData.article.sort(function (a, b) {
      if (a.date < b.date) return 1;
      if (a.date > b.date) return -1;
      return 0;
    });

    for (let i = 0; i < sortData.length; i++) {
      // jsonでの指定件数のみ表示させる
      if (i >= Number(jsonData.count)) {
        break;
      }

      tmpArray.push(jsonData.article[i]);
    }

    //console.log(tmpArray);

    return tmpArray;
  };

  /**
   * 初期レンダー後に動く処理
   */
  useEffect(() => {
    // 非同期処理を開始
    Promise.all([getNews()]);
  }, []);

  /**
   * Newsを取得する
   */
  const getNews = async () => {
    const res = await axios({
      method: 'get',
      baseURL: './',
      url: 'data/news.json',
      responseType: 'json',
    });
    if (res.status === 200) {
      setNews(prepareNews(res.data));
    }
  };

  /**
   * READ MOREボタン
   * @param urlName 遷移先URL
   * @returns
   */
  const moreButton = (urlName: string) => {
    return (
      <ScrollReveal
        option={{
          viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
          duration: DURATION.BUTTON,
          reset: false,
          opacity: 0,
          delay: DELAYTIME.BUTTON,
        }}
      >
        <ColorChangeLink tabIndex={0} to={urlName}>
          <p
            style={{
              fontSize: `${width / 64}px`,
            }}
          >
            MORE
          </p>
        </ColorChangeLink>
      </ScrollReveal>
    );
  };

  /**
   * サービス内容の説明箇所
   * @param text 説明文
   * @param commentText 一言コメント
   * @param image 画像名
   * @param borderFlag 区切りの線をつけるか
   * @param time 表示遅延時間
   * @returns
   */
  const serviceContent = (
    text: string,
    commentText: string,
    image: string,
    borderFlag: boolean,
    time: number
  ) => {
    return (
      <Grid item xs={6} sm={4}>
        <TrimCircle
          fontSize={`${width / 53}px`}
          text={text}
          comment={commentText}
          imageName={image}
          alt={image}
          borderFlag={borderFlag}
          style={{
            width: `${width / 7.1}px`,
            height: `${width / 7.1}px`,
            borderRadius: '50%',
          }}
          paperStyle={{ borderRadius: '50%' }}
          padding={'2px 0px'}
          option={{
            viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
            origin: 'left',
            distance: '1000px',
            rotate: { x: 0, y: 0, z: 180 },
            duration: DURATION.SERVICE,
            reset: false,
            opacity: 0,
            delay: time * DELAYTIME.SERVICE,
          }}
        ></TrimCircle>
      </Grid>
    );
  };

  /**
   * ニュースとフェイスブック処理
   * @returns
   */
  const newsAndFaceBook = () => {
    return (
      <>
        <Grid item lg={12} sm={12} style={{ paddingBottom: '64px' }}>
          <ScrollReveal
            option={{
              origin: 'left',
              distance: '1000px',
              duration: DURATION.NEWS,
              reset: false,
              opacity: 0,
              delay: DELAYTIME.NEWS,
            }}
          >
            <News cell={['投稿日', '記事タイトル']} data={news}></News>
          </ScrollReveal>
        </Grid>
      </>
    );
  };

  /**
   * テキストを1文字ずつ表示する処理
   * @param text テキスト
   * @returns
   */
  const oneChacterByDisplay = (text: string) => {
    return (
      <div style={{ display: 'inline-flex' }}>
        {t(text)
          .split('')
          .map((item, index) => (
            <OneCharacterByDisplay
              key={index}
              text={item}
              delayTime={index * 100 * DELAYTIME.SUB_TITLE}
              styles={{
                fontSize: `max(${width / 64}px, 10px)`,
                color: COLORS.FONT,
                letterSpacing: '10px',
                fontWeight: 'bold',
                marginBottom: `${width / 42}px`,
              }}
            ></OneCharacterByDisplay>
          ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Fab
        sx={{
          position: 'fixed',
          bottom: '90px',
          right: '20px',
          background: 'black',
          '&:hover': {
            background: 'black',
          },
        }}
        onClick={() => {
          window.open(`https://twitter.com/airspo_fukuoka`);
        }}
      >
        <FaXTwitter color={'#ffff'} size={30} />
      </Fab>
      <Fab
        sx={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          background: '#039BE5',
          p: 0,
          '&:hover': {
            background: '#039BE5',
          },
        }}
        onClick={() => {
          window.open(`https://www.facebook.com/airspo.co.jp`);
        }}
      >
        <ImFacebook color="#ffff" size={30} />
      </Fab>

      <div
        style={{
          maxWidth: '1920px',
          padding: '120px 0px 24px',
          margin: 'auto',
          overflow: 'hidden',
        }}
      >
        <ThemeProvider theme={apptheme}>
          {/* 空気のようにテクノロジーを身近に */}
          <div style={{ position: 'relative', minHeight: `${width / 1.8}px` }}>
            <ScrollReveal
              option={{
                viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                duration: DURATION.PHRASE,
                scale: 0.1,
                reset: false,
                opacity: 0,
                delay: DELAYTIME.PHRASE,
              }}
            >
              <TrimCircle
                fontSize={`${width / 53}px`}
                text={''}
                imageName={'home_01'}
                alt={'home_01'}
                borderFlag={false}
                style={{ width: '100%', height: '100%', minHeight: '300px', borderRadius: '0%' }}
                padding={'2px 0px'}
              ></TrimCircle>
              <div
                // 「空気のようにテクノロジーを身近に」の文字背景
                style={{
                  backgroundColor: COLORS.TITLE_BACK,
                  borderRadius: '5%',
                  position: 'absolute',
                  top: '50%',
                  left: '20%',
                  color: 'white',
                  transform: 'translate(-20%,-50%)',
                  textAlign: 'center',
                  margin: '0px 10px',
                }}
              >
                <TypeWriterEffect
                  textStyle={{
                    fontFamily: '"Noto Sans JP"',
                    fontSize: `${width / 15}px`,
                    color: COLORS.TITLE_FONT,
                    padding: '24px 24px 24px 24px',
                  }}
                  startDelay={TYPEWRITER.DELAY1}
                  cursorColor={COLORS.CURSOR}
                  text={t('空気のように')}
                  typeSpeed={TYPEWRITER.SPEED}
                  hideCursorAfterText={true}
                />
                <TypeWriterEffect
                  textStyle={{
                    fontFamily: '"Noto Sans JP"',
                    fontSize: `${width / 15}px`,
                    color: COLORS.TITLE_FONT,
                    padding: '24px 24px 24px 24px',
                  }}
                  startDelay={TYPEWRITER.DELAY2}
                  cursorColor={COLORS.CURSOR}
                  text={t('テクノロジーを身近に')}
                  typeSpeed={TYPEWRITER.SPEED}
                  hideCursorAfterText={true}
                />
              </div>
            </ScrollReveal>
          </div>

          {/* Vision */}
          <div
            style={{
              position: 'relative',
              minHeight: `${width / 1.8}px`,
            }}
          >
            <TrimCircle
              fontSize={`${width / 53}px`}
              text={''}
              imageName={'home_02'}
              alt={'home_02'}
              borderFlag={false}
              style={{
                width: '100%',
                height: '100%',
                minHeight: '300px',
                borderRadius: '0%',
                opacity: '30%',
              }}
              padding={'2px 0px'}
            ></TrimCircle>

            <div
              // 画面サイズが700以下まで小さくなった場合は、テキストの表示方法を左寄せにする
              style={
                width >= 700
                  ? {
                      width: '100%',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(-50%,-50%)',
                      textAlign: 'center',
                      margin: '0px 10px',
                    }
                  : {
                      position: 'absolute',
                      top: '50%',
                      left: '0',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(0%,-50%)',
                      textAlign: 'start',
                      margin: '0px 10px',
                    }
              }
            >
              {oneChacterByDisplay(t('home.VISION'))}

              {messageText('home.ビジョン見出し', infoBoldTypo())}
              {messageText('home.ビジョン説明文')}
              <div>{moreButton('../aboutUs')}</div>
            </div>
          </div>

          {/* Service */}
          <div
            style={{
              textAlign: 'center',
              paddingTop: `${width / 20}px`,
              paddingBottom: `${width / 20}px`,
            }}
          >
            {oneChacterByDisplay(t('home.SERVICE'))}

            <Grid container item xs>
              {serviceContent(
                'ソフトウェア開発支援',
                'ソフトウェアのことなら任せとけ！',
                'home_03',
                true,
                500
              )}
              {serviceContent(
                'ソフトウェアによる業務改善',
                'これからよろしくお願いします！',
                'home_04',
                true,
                300
              )}
              {serviceContent(
                'ＶＰＮサーバーの構築',
                'これで家からでも仕事ができるぞ！',
                'home_05',
                false,
                100
              )}
            </Grid>

            {moreButton('../service')}
          </div>

          {/* Projects */}
          <div
            style={{
              textAlign: 'center',
              paddingTop: `${width / 20}px`,
              paddingBottom: `${width / 20}px`,
              backgroundColor: COLORS.BODY_BACK,
            }}
          >
            {oneChacterByDisplay(t('home.PROJECTS'))}

            <ScrollReveal
              option={{
                viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                duration: DURATION.SUB_TITLE,
                reset: false,
                opacity: 0,
                delay: 500 * DELAYTIME.SUB_TITLE,
              }}
            >
              <Typography
                style={{
                  fontSize: `${width / 40}px`,
                  fontWeight: 'bold',
                  color: COLORS.FONT,
                  letterSpacing: '10px',
                  textAlign: 'center',
                }}
              >
                MAIN DEVELOPMENT SUPPORT
              </Typography>
              <Typography
                style={{
                  fontSize: `max(${width / 64}px, 8px)`,
                  color: COLORS.FONT,
                  letterSpacing: '10px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                主な開発支援
              </Typography>
            </ScrollReveal>

            <div style={{ paddingTop: `${width / 20}px` }}>
              <div>
                <Grid container item xs={12}>
                  {AchievementDataDevelop.achievementDataDevelop.length > 0 &&
                    AchievementDataDevelop.achievementDataDevelop?.map(
                      (item, index) =>
                        // isHomeのフラグがtrueになっているデータだけ表示する
                        item.isHome && (
                          <HomeCardDevelop
                            flexEndFlag={index % 2 === 0 ? true : false}
                            key={index}
                            title={item.title}
                            text={item.text}
                            developerName={''}
                            comment={item.comment}
                            color={item.color}
                            you={item.you}
                            ki={item.ki}
                            sei={item.sei}
                            tan={item.tan}
                            ketu={item.ketu}
                          />
                        )
                    )}
                </Grid>
              </div>
            </div>
          </div>

          <div style={{ backgroundColor: COLORS.BODY_BACK }}>
            <ScrollReveal
              option={{
                viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                duration: DURATION.PHRASE,
                reset: false,
                opacity: 0,
                delay: 500 * DELAYTIME.SUB_TITLE,
              }}
            >
              <Typography
                style={{
                  fontSize: `${width / 40}px`,
                  fontWeight: 'bold',
                  color: COLORS.FONT,
                  letterSpacing: '10px',
                  textAlign: 'center',
                  paddingTop: `${width / 20}px`,
                }}
              >
                INTERNAL DEVELOPMENT
              </Typography>
              <Typography
                style={{
                  fontSize: `max(${width / 64}px, 8px)`,
                  color: COLORS.FONT,
                  letterSpacing: '10px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                自社開発
              </Typography>
            </ScrollReveal>

            <div style={{ padding: `${width / 20}px 0px` }}>
              <div>
                <Grid container item xs={12}>
                  {AchievementDataOrigin.achievementDataOrigin.length > 0 &&
                    AchievementDataOrigin.achievementDataOrigin?.map(
                      (item, index) =>
                        // isHomeのフラグがtrueになっているデータだけ表示する
                        item.isHome && (
                          <HomeCardOrigin
                            flexEndFlag={index % 2 === 0 ? true : false}
                            key={index}
                            title={item.title}
                            developerName={''}
                            text={item.text}
                            comment={item.comment}
                            color={item.color}
                          />
                        )
                    )}
                </Grid>
              </div>
            </div>
          </div>

          {/* Recruit */}
          <div
            style={{
              position: 'relative',
              minHeight: `${width / 1.8}px`,
            }}
          >
            <TrimCircle
              fontSize={`${width / 53}px`}
              text={''}
              imageName={'home_06'}
              alt={'home_06'}
              borderFlag={false}
              style={{
                width: '100%',
                height: '100%',
                minHeight: '300px',
                borderRadius: '0%',
                opacity: '50%',
              }}
              padding={'2px 0px'}
            ></TrimCircle>
            <div
              style={
                width >= 700
                  ? {
                      width: '100%',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(-50%,-50%)',
                      textAlign: 'center',
                      margin: '0px 10px',
                    }
                  : {
                      position: 'absolute',
                      top: '50%',
                      left: '0',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(0%,-50%)',
                      textAlign: 'start',
                      margin: '0px 10px',
                    }
              }
            >
              {oneChacterByDisplay(t('home.RECRUIT'))}
              {messageText('home.リクルート見出し', infoBoldTypo())}
              {messageText('home.リクルート説明文')}

              {moreButton('../recruitInfo')}
            </div>
          </div>

          {isNewsVisible.current === true && (
            <div
              style={{
                textAlign: 'center',
                backgroundColor: COLORS.BODY_BACK,
                paddingTop: `${width / 20}px`,
                paddingBottom: `${width / 20}px`,
              }}
            >
              {oneChacterByDisplay(t('home.NEWS'))}

              <ScrollReveal
                option={{
                  viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                  origin: 'left',
                  distance: '1000px',
                  duration: DURATION.NEWS,
                  reset: false,
                  opacity: 0,
                  delay: DELAYTIME.NEWS,
                }}
              >
                {/* モバイルかどうかで配置方法を変更する */}
                {isMobile ? (
                  <Grid item xs style={{ paddingBottom: '64px', flexDirection: 'row' }}>
                    {newsAndFaceBook()}
                  </Grid>
                ) : (
                  <Grid container item xs style={{ paddingBottom: '64px', flexDirection: 'row' }}>
                    {newsAndFaceBook()}
                  </Grid>
                )}
              </ScrollReveal>
            </div>
          )}
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
};

export default Home;
