// import { SessionKey } from 'constants/sessionKey';

import React, { ReactNode, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import BusinessIcon from '@mui/icons-material/Business';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ConstructionIcon from '@mui/icons-material/Construction';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DvrIcon from '@mui/icons-material/Dvr';
import Groups2Icon from '@mui/icons-material/Groups2';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import PublicIcon from '@mui/icons-material/Public';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Toolbar } from '@mui/material';
import { AppBar } from '@mui/material';
import {
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Switch,
  // Typography,
} from '@mui/material';
import { useWindowSize } from 'common/utils';
import i18n from 'i18n/configs';

/**
 * 言語切り替え用スイッチのテーマ情報
 */
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

/**
 * ヘッダー用
 * @returns
 */
const Header: React.FC = () => {
  // const [state, setState] = React.useState(
  //   sessionStorage.getItem(SessionKey.language) === 'en' ? true : false
  // );

  // useEffect(() => {
  //   if (sessionStorage.getItem(SessionKey.language) === null) {
  //     setState(false);
  //     sessionStorage.setItem(SessionKey.language, 'ja');
  //   }
  // }, []);

  i18n.changeLanguage('ja'); // 言語切り替え

  // Drawer の開閉状態（フックを利用）
  const [drawerOpen, setDrawerOpen] = useState(false);
  // Drawer の開閉
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen); // Drawer の開閉状態を反転
  };

  // 画面サイズ取得
  const [width] = useWindowSize();

  const location = useLocation();
  /**
   * 言語変更時の処理
   * @param event 切り替え情報
   */
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setState(event.target.checked);
  //   // セッションストレージ更新
  //   sessionStorage.setItem(SessionKey.language, event.target.checked ? 'en' : 'ja');
  //   // 画面リロード
  //   window.location.reload();
  // };

  /**
   * 項目ボタン
   * @param xs extra-small
   * @param sm small
   * @param to 遷移情報
   * @param categoryName カテゴリ名
   * @returns
   */
  const categoryButton = (xs: number, sm: number, to: string, categoryName: string) => {
    return (
      <Grid container item xs={xs} sm={sm} style={{ justifyContent: 'center' }}>
        <Button
          variant="text"
          color="primary"
          component={Link}
          to={to}
          style={{
            fontWeight: 'bold',
            background:
              `..${location.pathname}` === to
                ? 'linear-gradient(transparent 55%, #0066B7 140%)'
                : '',
          }}
        >
          {categoryName}
        </Button>
      </Grid>
    );
  };

  /**
   * メニュー項目のリスト
   * @param to ページ遷移先
   * @param primary 項目名英語
   * @param secondary 項目名日本語
   * @param icon アイコン
   * @returns
   */
  const listItem = (to: string, primary: string, secondary: string, icon: ReactNode) => {
    return (
      <ListItem onClick={handleDrawerToggle} component={Link} to={to}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    );
  };

  // 画面横サイズによって、メニューアイコンに変化する
  return (
    <div style={{ flexGrow: 1 }}>
      {width >= 1050 ? (
        <AppBar position="fixed" style={{ backgroundColor: 'rgba(255,255,255,0.4)' }}>
          <Toolbar>
            <Grid
              container
              item
              xs={12}
              style={{
                flexDirection: 'row',
                backgroundColor: 'rgba(255,255,255,0.8)',
                opacity: '100%',
              }}
            >
              <Grid container item xs={3} sm={3} style={{ justifyContent: 'center' }}>
                <Button variant="text" color="primary" component={Link} to="../">
                  <img src={`${process.env.PUBLIC_URL}/new_logo2024.png`} alt="logo" />
                </Button>
              </Grid>
              {categoryButton(1, 1, '../aboutUs', '私たち')}
              {categoryButton(1, 1, '../service', '事業内容')}
              {categoryButton(1, 1, '../achievement', '開発実績')}
              {categoryButton(1, 1, '../companyInfo', '企業情報')}
              {categoryButton(1, 1, '../recruitInfo', '採用情報')}
              {categoryButton(1, 1, '../product', '製品')}
              {categoryButton(1, 1, '../webService', '公開APP')}
              {categoryButton(1, 1, '../inHouseService', '社内APP')}
              <Grid
                container
                item
                xs={1}
                sm={1}
                style={{ justifyContent: 'center', flexDirection: 'row' }}
              >
                {/* <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                    style={{ paddingTop: '10px' }}
                  >
                    <Grid item style={{ color: '#2f2f2f' }}>
                      Ja
                    </Grid>
                    <Grid item>
                      <AntSwitch checked={state} onChange={handleChange} name="checkedC" />
                    </Grid>
                    <Grid item style={{ color: '#2f2f2f' }}>
                      En
                    </Grid>
                  </Grid>
                </Typography> */}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      ) : (
        <>
          <AppBar position="fixed" style={{ backgroundColor: 'rgba(255,255,255,0.4)' }}>
            <Toolbar style={{ backgroundColor: 'rgba(255,255,255,0.8)', opacity: '100%' }}>
              <Grid container item xs style={{ flexDirection: 'row' }}>
                <Grid container item xs={9}>
                  <Button
                    variant="text"
                    color="primary"
                    component={Link}
                    to="../"
                    style={{ padding: '0px' }}
                  >
                    <img
                      style={{ width: '150%', height: 'auto' , left: '20px'}}
                      src={`${process.env.PUBLIC_URL}/new_logo2024.png`}
                      alt="logo"
                    />
                  </Button>
                </Grid>
                <Grid container item xs={3} style={{ flexDirection: 'column', textAlign: 'end' }}>
                  <Grid item xs>
                    {/* <Typography component="div">
                      <Grid
                        container
                        item
                        xs={12}
                        component="label"
                        alignItems="center"
                        spacing={1}
                        style={{ paddingTop: '10px' }}
                      >
                        <Grid item style={{ color: '#2f2f2f' }}>
                          Ja
                        </Grid>
                        <Grid item>
                          <AntSwitch checked={state} onChange={handleChange} name="checkedC" />
                        </Grid>
                        <Grid item style={{ color: '#2f2f2f' }}>
                          En
                        </Grid>
                      </Grid>
                    </Typography> */}
                  </Grid>
                  <Grid item xs>
                    <IconButton color="primary" onClick={handleDrawerToggle}>
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Drawer anchor="top" variant="temporary" open={drawerOpen} onClose={handleDrawerToggle}>
            <DrawerHeader>
              <IconButton onClick={handleDrawerToggle}>
                <ChevronRightIcon style={{ transform: 'rotate(-90deg)' }} />
              </IconButton>
            </DrawerHeader>{' '}
            <List>
              {listItem('/', 'Home', '', <HomeIcon />)}
              {listItem('../aboutUs', 'AboutUs', '私たち', <Groups2Icon />)}
              {listItem('../service', 'Service', '事業内容', <MiscellaneousServicesIcon />)}
              {listItem('../achievement', 'Achievement', '開発実績', <DvrIcon />)}
              {listItem('../companyInfo', 'Company', '企業情報', <BusinessIcon />)}
              {listItem('../recruitInfo', 'Recruit', '採用情報', <ContactMailIcon />)}
              {listItem('../product', 'Product', '製品', <ConstructionIcon />)}
              {listItem('../webService', 'WebService', '公開APP', <PublicIcon />)}
              {listItem('../inHouseService', 'InHouseService', '社内APP', <VpnKeyIcon />)}
            </List>
          </Drawer>
        </>
      )}
    </div>
  );
};

export default Header;